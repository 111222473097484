@import url('https://fonts.googleapis.com/css2?family=Atma&family=Galada&family=Mina&family=Tiro+Bangla&display=swap');
body {  
  padding: 7px;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
main{
  min-height: 220px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
article{
  width:80%;
  font-family: Tiro Bangla;
}
button{
  cursor: pointer;
}

@media only screen and (max-width:768px) {
  article{
    width:95%;
  }
}